.login-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #4c526a;
  background: #fff;

  .subTitle-container {
    width: 100%;
    height: 80px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;

    .subTitle {
      width: 1100px;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;

      .home {
        color: red;
        cursor: pointer;
      }

      .title-box {
        display: flex;
        align-items: center;
        font-size: 22px;

        .logo {
          // width: auto;
          // height: 100%;
          width: 237px;
          height: 80px;
          margin-right: 30px;
        }
      }
    }
  }

  .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    padding: 52px 0;
    background: url('https://fx-pangu.oss-cn-hangzhou.aliyuncs.com/tmpdir/202006191132250001822749.png') no-repeat;
    background-size: 100% 100%;

    .login-img {
      width: 570px;
      height: 426px;
      overflow: hidden;
      margin-right: 60px;
      background: #f7f8fc;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .login-form {
      width: 460px;
      height: 426px;
      padding: 35px 38px;
      border-radius: 2px;
      border: 1px solid #dfdfdf;
      background: #fff;

      .login-title {
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 11px;
        margin-bottom: 30px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        b {
          display: inline-block;
          width: 3px;
          height: 21px;
          background: #979797;
          margin: 0 30px;
        }

        span:first-child {
          color: #333;
        }
      }

      .ant-form-item {
        height: 46px;
      }

      .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 10px;
      }

      .login-form-button {
        width: 100%;
        height: 46px;
        margin-bottom: 47px;
        margin-top: 15px;
        background-color: #ff440a;
        border-color: #ff440a;
      }

      .txt {
        text-align: right;
        font-size: 16px;

        .forget {
          font-size: 16px;
          color: #666;
        }

        .register {
          color: #ff440a;
          font-size: 16px;
        }
      }
    }

    :global {
      .ant-input-affix-wrapper .ant-input-prefix {
        width: 44px;
        height: 44px;
        background: #ddd;
        left: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}