.register-wrap {
  height: 100%;
  padding-top: 64px;

  .register-box {
    width: 882px;
    margin: 129px auto 0;
  }

  .ant-steps {
    width: 860px;
    margin-bottom: 70px;
    // .ant-steps-item.ant-steps-item-active
  }

  .ant-steps-item-container {
    position: relative;
  }

  .ant-steps-item-icon {
    width: 56px;
    height: 56px;
    background: #D9D9D9;
    border: 2px solid #d1d1d1;
    line-height: 52px;
    text-align: center;
    font-size: 36px;
    position: relative;
    z-index: 2;
  }

  .ant-steps-icon {
    color: #fff !important;
  }

  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 28px;
    left: 10px;
  }

  .ant-steps-dot .ant-steps-item-content,
  .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 190px;
  }

  .ant-steps-item-tail {
    background: #e8e8e8;
  }

  .ant-steps-item-title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      font-size: 24px;
    }
  }

  .ant-steps-item-finish,
  .ant-steps-item-active {
    .ant-steps-item-icon {
      background: #ff440a;
      border: 5px solid #f5cdcd;
      width: 56px;
      height: 56px;
      font-size: 24px;
      position: relative;
      z-index: 2;
      line-height: 46px;
    }

    .ant-steps-item-title {
      color: #ff440a !important;
    }

    .ant-steps-item-tail::after {
      background: #ff440a;
      width: 170px;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background: #ff440a;
      width: 340px;
    }
  }

  .register-form {
    margin-left: 190px;

    &-input {
      width: 450px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #979797;

      .ant-input-prefix {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 80px;
        margin-right: 8px;
        color: #666666;
      }
    }

    .check-btn {
      width: 129px;
      height: 46px;
      position: relative;
      top: 9px;
      left: 27px;

      &:hover {
        color: #ff440a;
        border-color: #ff440a;
      }
    }

    .check-res {
      display: inline-block;
      position: relative;
      top: 7px;
      left: 20px;

      &.ok {
        color: #85b0fd;
      }

      &.no {
        color: #ED3833;
      }
    }

    .form-ok-btn {
      width: 450px;
      height: 46px;
      margin-top: 40px;
      color: #fff;
      font-size: 20px;
      background-color: #ff440a;

      &.ant-btn-danger[disabled] {
        background: #ff9694;
      }
    }

    .ant-form-item-extra {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ed3833;
    }

    .ant-form-item-children {
      position: relative;
    }

    .code-btn {
      width: 166px;
      height: 46px;
      position: absolute;
      left: 285px;
      top: 0;
      background: #ff440a;
      color: #fff;
    }

    .register-success {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-right: 190px;

      .success-tips {
        margin-bottom: 16px;
        font-size: 16px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}