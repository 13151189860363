//colors
$--color-theme: #ff440a;
$--color-auxiliary-orange: #ff812d;
$--color-auxiliary-blue: #2a8ff7;
$--color-success: #5bc531;
$--color-warn: #f5ad42;
$--color-error:#ed494c;
$--color-white:#fff;
$--color-text-dark:#4a4a4a;
$--color-text-grey:#666;
$--color-text-light:#9b9b9b;
$--color-bg-dark:#444;
$--color-bg-grey:#666;
$--color-bg-light:#f7f8fa;
$--color-line:#dedede;
$--color-divider:#ededef;

//sizes
$--size-line:1px;
$--size-line-bold:2px;
$--size-text-32:32px;
$--size-text-24:24px;
$--size-text-20:20px;
$--size-text-16:16px;
$--size-text-18:18px;
$--size-text-14:14px;
$--size-text-12:12px;

//distances
$--distance-gap-8:8px;
$--distance-gap-16:16px;
$--distance-gap-24:24px;
$--distance-gap-32:32px;
$--distance-gap-40:40px;
$--distance-gap-48:48px;
$--distance-gap-56:56px;
$--distance-gap-64:64px;


//styles
$--style-solid-border:$--size-line solid $--color-line;
$--style-dashed-border:$--size-line dashed $--color-line;


//others
$--page-content-width: 1012px;





