@charset "UTF-8";
#root {
  height: 100%;
  overflow: auto;
  background-color: #fafafa;
  font-size: 14px;
  color: #333;
  /* 角色权限树默认样式修改 */ }
  #root p {
    margin-bottom: 0; }
  #root img {
    vertical-align: middle;
    border-style: none; }
  #root .c-body-wrap {
    width: 96%;
    height: calc(100vh - 60px);
    min-width: 800px;
    margin: 30px auto 0;
    position: relative; }
  #root .c-left-wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%; }
  #root .permission_tree .ant-tree-list-holder-inner {
    display: block !important; }
  #root .permission_tree .last_level {
    display: inline-flex !important; }
